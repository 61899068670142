import React from 'react'
import { Heading, Text, Center } from '@chakra-ui/react'

const SectionAbout = () => {
  return (
    <div className='flex justify-center md:mt-3'>
      <div className='max-w-2xl w-full'>
        <Heading>About</Heading>
        <Text fontSize='xl' className='text-justify'>
          I'm a web developer from Philippines, working in web development and designs. I enjoy coding and exploring new programming technology stack, applying it into my old projects to serve as an exercise.
        </Text>
        <Text fontSize='xl' className='mt-2 text-justify'>
          My aim is to bring your ideas and logic into a working web application product.
        </Text>
      </div>
    </div>
  )
}

export default SectionAbout