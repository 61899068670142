import React from 'react'
import { Avatar, Center, Heading, IconButton, Link } from '@chakra-ui/react'
import Typewriter from 'typewriter-effect'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa'

const SectionAvatar = () => {
  const Social = ({ url, label, Icon }) => {
    return (
      <Link href={url} isExternal>
        <IconButton 
          variant='outline'
          aria-label={label}
          size='md'
          icon={Icon} />
      </Link>
    )
  }

  return (
    <Center>
      <div className='grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-2'>
        <div className='h-full flex items-center justify-center'>
          <Avatar size='2xl' name='Mark Dave Telmo' src='https://i.imgur.com/aWknIHH.jpg' />{' '}
        </div>
        <div className='h-full flex items-center'>
          <div>
            <Heading as='h2' size='xl'>
              Mark Dave Telmo
            </Heading>
            <Heading as='h4' size='sm' className='flex md:inline-flex justify-center'>
              I'm a&nbsp;<Typewriter
                options={{
                  strings: ['Designer', 'Developer', 'Freelancer'],
                  autoStart: true,
                  loop: true,
                }}
              />
            </Heading>
            <Center className='mt-3 flex md:inline-flex justify-center gap-3'>
              <Social url='https://www.facebook.com/lovechaser26' label='Facebook' Icon={<FaFacebookF />} />
              {/* <Social url='https://chakra-ui.com' label='Twitter' Icon={<FaTwitter />} /> */}
              {/* <Social url='https://chakra-ui.com' label='Instagram' Icon={<FaInstagram />} /> */}
              <Social url='https://www.linkedin.com/in/markdavealveyratelmo' label='LinkedIn' Icon={<FaLinkedinIn />} />
            </Center>
          </div>
        </div>
      </div>
    </Center>
  )
}

export default SectionAvatar