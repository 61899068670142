import React from 'react'
import { Heading, Link, Text } from '@chakra-ui/react'

const SectionContact = () => {
  return (
    <div className='flex justify-center mt-3'>
      <div className='max-w-2xl w-full text-center'>
        <Heading>Contact me</Heading>
        <Text fontSize='xl' className='text-center'>
          You can contact me by my <Link href='https://mail.google.com/mail/?view=cm&to=markdavealveyratelmo@gmail.com&su=&body=' style={{textDecoration: 'none'}} isExternal><span fontSize='xl' className='text-blue-600'>EMAIL</span></Link> or send me an <Link href='tel:+639566697574' style={{textDecoration: 'none'}}><span fontSize='xl' className='text-blue-600'>SMS</span></Link>.
        </Text>
      </div>
    </div>
  )
}

export default SectionContact