import React from 'react'
import { Center, Divider, Grid, GridItem, IconButton, Image, Link, Text } from '@chakra-ui/react'
import { FaGithub, FaFacebookMessenger } from 'react-icons/fa'
import Logo from '../Assets/Images/logo500x250.png'
// import Favicon from '../Assets/Images/logo100x50.png'

const SectionFooter = () => {
  return (
    <div className='flex justify-center mt-3 md:mt-10'>
      <div className='max-w-2xl w-full text-center'>
        <Divider />
        <Grid className='grid-cols-1 md:grid-cols-3' gap={2}>
          <GridItem w='100%'>
            <Image src={Logo} fallbackSrc='https://via.placeholder.com/100' alt='Card Image' className='mx-auto md:mx-0' boxSize='100px' height={50} />
          </GridItem>
          <GridItem w='100%'>
            <Text fontSize='xl' className='py-2 md:py-4'>Copyright © {new Date().getFullYear()}</Text>
          </GridItem>
          <GridItem w='100%'>
            <Center className='mt-3 flex md:inline-flex justify-center gap-3'>
              <Link href='https://github.com/emdieytea' isExternal>
                <IconButton 
                  variant='outline'
                  aria-label='Github'
                  size='md'
                  icon={<FaGithub />} />
              </Link>
              <Link href='https://www.messenger.com/t/lovechaser26' isExternal>
                <IconButton 
                  variant='outline'
                  aria-label='Messenger'
                  size='md'
                  icon={<FaFacebookMessenger />} />
              </Link>
            </Center>
          </GridItem>
        </Grid>
      </div>
    </div>
  )
}

export default SectionFooter