import React from 'react'
import { Heading, Center, Text, Grid, GridItem } from '@chakra-ui/react'
import { SiCplusplus, SiCsharp, SiJava, SiHtml5, SiCss3, SiJavascript, SiNodedotjs, SiPython, SiMysql, SiPostgresql, SiArduino, SiLaravel, SiCodeigniter, SiReact, SiMongodb, SiPhp, SiDocker, SiTailwindcss } from 'react-icons/si'
import { TbBrandReactNative } from 'react-icons/tb'

const SectionTechStack = () => {
  const TechStack = ({ Icon, name }) => {
    return (
      <GridItem w='100%'>
        <div className='w-full text-center inline-block'>
          <div className='flex justify-center'>
            {Icon}
          </div>
          <Text fontSize='sm'>{name}</Text>
        </div>
      </GridItem>
    )
  }

  return (
    <div className='flex justify-center mt-3'>
      <div className='max-w-2xl w-full'>
        <Heading>Technology Stack</Heading>
        <Grid className='mt-3 grid-cols-3 md:grid-cols-6' gap={2}>
          <TechStack Icon={<SiCplusplus size={48} />} name="C++" />
          <TechStack Icon={<SiArduino size={48} />} name="Arduino" />

          <TechStack Icon={<SiCsharp size={48} />} name="C#" />
          <TechStack Icon={<SiJava size={48} />} name="Java" />
          <TechStack Icon={<SiPython size={48} />} name="Python" />

          <TechStack Icon={<SiHtml5 size={48} />} name="HTML" />
          <TechStack Icon={<SiCss3 size={48} />} name="CSS" />
          <TechStack Icon={<SiTailwindcss size={48} />} name="Tailwind CSS" />

          <TechStack Icon={<SiJavascript size={48} />} name="Javascript" />
          <TechStack Icon={<SiNodedotjs size={48} />} name="Node.js" />
          <TechStack Icon={<TbBrandReactNative size={48} />} name="React Native" />
          <TechStack Icon={<SiReact size={48} />} name="ReactJS" />

          <TechStack Icon={<SiMysql size={48} />} name="MySQL" />
          <TechStack Icon={<SiPostgresql size={48} />} name="PostgreSQL" />
          <TechStack Icon={<SiMongodb size={48} />} name="MongoDB" />

          <TechStack Icon={<SiPhp size={48} />} name="PHP" />
          <TechStack Icon={<SiCodeigniter size={48} />} name="Codeigniter" />
          <TechStack Icon={<SiLaravel size={48} />} name="Laravel" />

          <TechStack Icon={<SiDocker size={48} />} name="Docker" />
        </Grid>
      </div>
    </div>
  )
}

export default SectionTechStack