import './App.css'

// My custom components
import SectionAvatar from './Components/SectionAvatar'
import SectionAbout from './Components/SectionAbout'
import SectionTechStack from './Components/SectionTechStack'
import SectionPortfolio from './Components/SectionPortfolio'
import SectionContact from './Components/SectionContact'
import SectionFooter from './Components/SectionFooter'

// 1. import `ChakraProvider` component
import { ChakraProvider, Container } from '@chakra-ui/react'

function App() {
  return (
    <ChakraProvider>
      <Container maxW='container.xl'>
        <div className='py-5 md:py-20 px-5 md:px-30'>
          <SectionAvatar />
          <SectionAbout />
          <SectionTechStack />
          <SectionPortfolio />
          <SectionContact />
          <SectionFooter />
        </div>
      </Container>
    </ChakraProvider>
  )
}

export default App
