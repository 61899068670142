import React from 'react'
import { Heading, Text, Divider, Grid, GridItem, Box, Image, Stack, Badge, Flex, Spacer, Button, Link } from '@chakra-ui/react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { SiBootstrap, SiCss3, SiHtml5, SiJavascript, SiLaravel, SiMysql, SiPhp, SiGooglemaps, SiReact, SiNodedotjs, SiPostgresql, SiTailwindcss, SiDocker, SiMongodb, SiExpress } from 'react-icons/si'

const SectionPortfolio = () => {
  const PortfolioCard = ({ imageUrl, imageAlt, typeColor, type, title, description, techStacks = [] }) => {
    return (
      <div>
        <Box className='mx-auto' w='300px' rounded='20px' overflow='hidden' bg={'gray.200'} mt={5}>
          {imageUrl && <Image src='' fallbackSrc={imageUrl} alt={imageAlt} boxSize='300px' height={200} />}
          <Box p={5}>
            <div className='space-x-1'>
              <Badge variant='solid' colorScheme={typeColor} rounded='full' px={2}>{type}</Badge>
            </div>
            <Stack align='center'>
              <Text as='h2' fontWeight='normal' my={2}>{title}</Text>
              <Text fontWeight='light'>{description}</Text>
            </Stack>
            <Box w='100%' p={4} color='white' className='space-x-1 flex justify-center'>
              {/* Max of 8 icons */}
              {techStacks.map(item => item)}
            </Box>
          </Box>
        </Box>
      </div>
    )
  }
  
  const portfolios = [
    // Unloop Connect (unloop.com)
    {
      imageUrl: '',
      imageAlt: '',
      typeColor: 'red',
      type: 'Accounting',
      title: 'Accounting System',
      description: 'A cloud-based accounting system for e-commerce, seamlessly integrating with platforms like Amazon and Shopify to streamline transaction mapping and COGS management.',
      techStacks: [
        // Max of 8 icons
        <SiTailwindcss size={24} color="black" />,
        <SiReact size={24} color="black" />,
        <SiNodedotjs size={24} color="black" />,
        <SiPostgresql size={24} color="black" />,
        <SiDocker size={24} color="black" />,
      ]
    },
    // Agency CRM
    {
      imageUrl: '',
      imageAlt: '',
      typeColor: 'purple',
      type: 'CRM',
      title: 'Customer Relationship Management',
      description: 'A system that helps businesses manage interactions with customers, track sales activities, and improve overall customer satisfaction and retention.',
      techStacks: [
        // Max of 8 icons
        <SiBootstrap size={24} color="black" />,
        <SiCss3 size={24} color="black" />,
        <SiHtml5 size={24} color="black" />,
        <SiLaravel size={24} color="black" />
      ]
    },
    // MSP KPI Dashboard
    {
      imageUrl: '',
      imageAlt: '',
      typeColor: 'pink',
      type: 'Dashboard',
      title: 'MSP KPI Dashboard',
      description: 'An intuitive tool that provides real-time insights into key performance indicators, streamlining decision-making, and optimizing project success effortlessly.',
      techStacks: [
        // Max of 8 icons
        <SiTailwindcss size={24} color="black" />,
        <SiCss3 size={24} color="black" />,
        <SiHtml5 size={24} color="black" />,
        <SiMongodb size={24} color="black" />,
        <SiExpress size={24} color="black" />,
        <SiReact size={24} color="black" />,
        <SiNodedotjs size={24} color="black" />
      ]
    },
    // Project Management System - PMS
    {
      imageUrl: '',
      imageAlt: '',
      typeColor: 'orange',
      type: 'PMS',
      title: 'Project Management System',
      description: 'A software that helps plan, track, and coordinate tasks, resources, and timelines for projects, ensuring efficient project execution and delivery.',
      techStacks: [
        // Max of 8 icons
        <SiBootstrap size={24} color="black" />,
        <SiCss3 size={24} color="black" />,
        <SiHtml5 size={24} color="black" />,
        <SiLaravel size={24} color="black" />
      ]
    },
    // Human Resource Information System - HRIS
    {
      imageUrl: '',
      imageAlt: '',
      typeColor: 'green',
      type: 'HRIS',
      title: 'Human Resource Information System',
      description: 'An application software that centralizes and automates various HR tasks and processes. It streamlines HR operations, improve efficiency, and ensure regulatory compliance.',
      techStacks: [
        // Max of 8 icons
        <SiBootstrap size={24} color="black" />,
        <SiCss3 size={24} color="black" />,
        <SiHtml5 size={24} color="black" />,
        <SiLaravel size={24} color="black" />
      ]
    },
    // MyFi (myfi.com.ph)
    {
      imageUrl: '',
      imageAlt: '',
      typeColor: 'gray',
      type: 'Information',
      title: 'Company Website',
      description: 'An information system for a company showcasing information about its products and services.',
      techStacks: [
        // Max of 8 icons
        <SiBootstrap size={24} color="black" />,
        <SiCss3 size={24} color="black" />,
        <SiHtml5 size={24} color="black" />,
        <SiReact size={24} color="black" />,
      ]
    },
    // Travel and Tours
    {
      imageUrl: '',
      imageAlt: '',
      typeColor: 'cyan',
      type: 'Reservation',
      title: 'Reservation System',
      description: 'An information system for a lodging hotel with reservation module.',
      techStacks: [
        // Max of 8 icons
        <SiBootstrap size={24} color="black" />,
        <SiCss3 size={24} color="black" />,
        <SiGooglemaps size={24} color="black" />,
        <SiHtml5 size={24} color="black" />,
        <SiJavascript size={24} color="black" />,
        <SiLaravel size={24} color="black" />,
        <SiMysql size={24} color="black" />,
        <SiPhp size={24} color="black" />,
      ]
    },
    // Sabfashion E-Commerce
    {
      imageUrl: '',
      imageAlt: '',
      typeColor: 'blue',
      type: 'E-Commerce',
      title: 'Apparel E-Commerce Website',
      description: 'A platform for users where they can browse and shop for wears which suites their taste.',
      techStacks: [
        // Max of 8 icons
        <SiBootstrap size={24} color="black" />,
        <SiCss3 size={24} color="black" />,
        <SiHtml5 size={24} color="black" />,
        <SiJavascript size={24} color="black" />,
        <SiLaravel size={24} color="black" />,
        <SiMysql size={24} color="black" />,
        <SiPhp size={24} color="black" />,
      ]
    }
  ];


  return (
    <div className='flex justify-center mt-3'>
      <div className='max-w-2xl w-full'>
        <Heading>Portfolio</Heading>
        <Carousel showThumbs={false} autoPlay={true} interval={2500} infiniteLoop={true} emulateTouch={true} showArrows={false}>
          {
            portfolios.map(item =>
              <PortfolioCard
                imageUrl={item.imageUrl}
                imageAlt={item.imageAlt}
                typeColor={item.typeColor}
                type={item.type}
                title={item.title}
                description={item.description}
                techStacks={item.techStacks}
              />
            )
          }
        </Carousel>
        {/* grid with no carousel */}
        {/* <Grid className='grid-cols-1 md:grid-cols-2' gap={2}>
          <GridItem w='100%'>
            <Box className='mx-auto' w='300px' rounded='20px' overflow='hidden' bg={'gray.200'} mt={5}>
              <Image src='https://media.geeksforgeeks.org/wp-content/uploads/20210727094649/img1.jpg' alt='Card Image' boxSize='300px' height={200} />
              <Box p={5}>
                <Stack align='center'>
                  <Badge variant='solid' colorScheme='green' rounded='full' px={2}>GeeksForGeeks</Badge>
                </Stack>
                <Stack align='center'>
                  <Text as='h2' fontWeight='normal' my={2}>A Computer Science Portal for Geeks</Text>
                  <Text fontWeight='light'>A platform for students to study CSE concepts asd sakld jadkl jaskdj klsjks jdkasj askdjw jdsakdjsak jaskldjs akjaskl jsakl.</Text>
                </Stack>
                <Flex>
                  <Spacer />
                  <Button variant='solid' colorScheme='green' size='sm'>Learn More</Button>
                </Flex>
              </Box>
            </Box>
          </GridItem>
          <GridItem w='100%'>
            <Box className='mx-auto' w='300px' rounded='20px' overflow='hidden' bg={'gray.200'} mt={5}>
              <Image src='https://media.geeksforgeeks.org/wp-content/uploads/20210727094649/img1.jpg' alt='Card Image' boxSize='300px' height={200} />
              <Box p={5}>
                <Stack align='center'>
                  <Badge variant='solid' colorScheme='green' rounded='full' px={2}>GeeksForGeeks</Badge>
                </Stack>
                <Stack align='center'>
                  <Text as='h2' fontWeight='normal' my={2}>A Computer Science Portal for Geeks</Text>
                  <Text fontWeight='light'>A platform for students to study CSE concepts asd sakld jadkl jaskdj klsjks jdkasj askdjw jdsakdjsak jaskldjs akjaskl jsakl.</Text>
                </Stack>
                <Flex>
                  <Spacer />
                  <Button variant='solid' colorScheme='green' size='sm'>Learn More</Button>
                </Flex>
              </Box>
            </Box>
          </GridItem>
        </Grid> */}
      </div>
    </div>
  )
}

export default SectionPortfolio